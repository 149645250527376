import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
import { useLocalization } from "gatsby-theme-i18n";
import _ from "lodash";
import Container from "./Container";
import blog from "../cms/blog/blog";
import ArticleCard from "./ArticleCard";
import ArrowButton from "./ArrowButton";

const KINDS = {
  fund_house: "Fund House",
  distributor: "Distributor",
};

function LatestUpdates({
  title,
  buttonTitle,
  isDark = false,
  kind = "distributor",
}) {
  const { locale, defaultLang } = useLocalization();
  const articles = Object.values(blog[locale] || blog[defaultLang]);
  const filteredArticles = articles
    .filter((item) => {
      const {
        attributes: { entity_types },
      } = item;
      const kindLabel = KINDS[kind];
      const isIncluded = entity_types.data
        .map(({ attributes }) => attributes.name)
        .includes(kindLabel);
      if (
        isIncluded ||
        (kind === "distributor" && _.isEmpty(entity_types.data))
      ) return item;
      return null;
    })
    .slice(0, 4);
  const backgroundColor = isDark ? "#1B365D" : "white";
  const color = isDark ? "white" : "#1B365D";

  if (_.isEmpty(filteredArticles)) return null;

  return (
    <Box py={{ xs: 2, sm: 5 }} sx={{ backgroundColor }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item container mb={3} alignItems="center" spacing={2}>
            <Grid item xs={12} sm={8}>
              <Typography color={color} variant="h3">
                {title || <Trans>Latest updates</Trans>}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm
              sx={{
                justifyContent: { sm: "end" },
                display: { sm: "flex" },
              }}
            >
              <ArrowButton
                path="/blog"
                title={buttonTitle || <Trans>View all</Trans>}
                isDark={!isDark}
              />
            </Grid>
          </Grid>
          {filteredArticles.map(({ attributes }) => (
            <Grid item xs={12} sm={6} md={3}>
              <ArticleCard attributes={attributes} isDark={isDark} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default LatestUpdates;
