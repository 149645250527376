import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Stack, Grid, Typography } from "@mui/material";
import {
  faWindow,
  faRocketLaunch,
  faRoad,
  faArrowsToCircle,
} from "@fortawesome/pro-light-svg-icons";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import NewLayout from "../../components/NewLayout";
import Hero from "../../components/Hero";
import Container from "../../components/Container";
import CustomizedIcon from "../../components/CustomizedIcon";
import backgroundImage from "../../images/headers/distributors-blockchain.png";
import LatestUpdates from "../../components/LatestUpdates";
import fastContentImage from "../../images/distributors/blockchain/fast.png";
import genContentImage from "../../images/distributors/blockchain/gen-content.png";
import partnerContentImage from "../../images/distributors/blockchain/partner-content.png";
import genImage from "../../images/distributors/blockchain/gen.png";
import partnerImage from "../../images/distributors/blockchain/partner.png";
import transferImage from "../../images/distributors/blockchain/transfer.png";
import { colors } from "../../theme";

const iconsContent = () => [
  {
    title: <Trans>A business platform ready to deliver savings</Trans>,
    description: (
      <Trans>
        An opportunity to deliver savings in a blockchain ecosystem and to
        understand the best adaptation strategy in a constantly changing
        environment
      </Trans>
    ),
    icon: faWindow,
    color: colors.green.main,
  },
  {
    title: <Trans>A way to be part of a new value chain</Trans>,
    description: (
      <Trans>
        It’s crucial to understand how the technology and its adoption can
        drastically reduce current core dealing activities in the value chain in
        every sector
      </Trans>
    ),
    icon: faRocketLaunch,
    color: colors.blue.main,
  },
  {
    title: <Trans>A technical solution tailored to a business ecosystem</Trans>,
    description: (
      <Trans>
        Dedicated privacy and anonymity features for data governance issues in
        blockchain ecosystems
      </Trans>
    ),
    icon: faRoad,
    color: colors.orangeLight.main,
  },
  {
    title: <Trans>Minimise disruptions</Trans>,
    description: (
      <Trans>
        Avoid long and expensive integration downtime when using our fully
        integrated tools
      </Trans>
    ),
    icon: faArrowsToCircle,
    color: colors.red.main,
  },
];

const valueContent = () => [
  {
    title: <Trans>FAST: Transfer Solution</Trans>,
    content: (
      <StyledBox>
        <Typography variant="h4">
          <Trans>Orchestrate fully digitalized transfer dealing flows</Trans>
        </Typography>
        <Typography mt={1}>
          <Trans>
            An integrated solution for the complete lifecycle of transfers with
            one single goal: a better service for end investors, providing
            greater efficiency to entities in a simplified technical integration
            scenario.
          </Trans>
        </Typography>
        <ul>
          <li>
            <Trans>
              <b>Time:</b> Avoid delays caused by current inefficient technical
              solutions by reducing third party data exchange activities and
              reconciliation activities.
            </Trans>
          </li>
          <li>
            <Trans>
              <b>Operational errors:</b> Real-time transfers request validation
              between entities thus reducing loss of opportunity due to data
              quality rejections, administration costs, and creating a better
              user experience for your final investors
            </Trans>
          </li>
          <li>
            <Trans>
              <b>Data access:</b> Real-time data and inter-entity transaction management to avoiding
              current inconsistencies. A transparent platform to monitor and
              audit all transactions and associated data
            </Trans>
          </li>
        </ul>
      </StyledBox>
    ),
    image: transferImage,
    contentImage: fastContentImage,
  },
  {
    title: <Trans>Digital Assets - Tokenization</Trans>,
    content: (
      <StyledBox>
        <Typography variant="h4">
          <Trans>Introducing asset tokenization to the fund sector</Trans>
        </Typography>
        <Typography mt={1}>
          <Trans>
            Allfunds Blockchain not only offers the potential to enhance the
            efficiency of all traditional fund related business, but also opens
            a door into the future of the industry: gaining access to the new
            digital asset ecosystem.
          </Trans>
        </Typography>
        <ul>
          <li>
            <Trans>
              Allfunds Blockchain has collaborated and advised on sandbox
              initiatives and was one of the main participants in successfully
              launching Spain’s first tokenized fund in 2022.
            </Trans>
          </li>
          <li>
            <Trans>
              Support with regulation: Allfunds Blockchain is an active
              supporter of the regulatory process and can assist in all phases
              of regulation and policy setting.
            </Trans>
          </li>
          <li>
            <Trans>
              A new ecosystem of business and services: A fully integrated fund
              management workflow with a digitalized and programmable fund
              lifecycle for an end-to-end fund distribution traceability.
            </Trans>
          </li>
          <li>
            <Trans>
              New distribution channels: The new era of Digital Assets can
              unlock new distribution channels and increase business
              opportunities.
            </Trans>
          </li>
        </ul>
      </StyledBox>
    ),
    image: genImage,
    contentImage: genContentImage,
  },
  {
    title: <Trans>Active partnerships</Trans>,
    content: (
      <StyledBox>
        <Typography>
          <Trans>
            Allfunds Blockchain is an active partner to many industry
            participants, having already worked with top Fund Houses, global
            Transfer Agents, Distributors and Regulators in transformation
            projects to adopt real blockchain technology solutions.
          </Trans>
        </Typography>
        <Typography mt={1}>
          <Trans>
            Using Allfunds' extensive experience in the fund business, our team
            has a thorough understanding of the fund industry's weak points and
            challenges, as well as how blockchain technology can alleviate these
            difficulties while saving money and paving the way for a tech-ready
            environment. By leading and coordinating different working groups in
            different countries, more than 40 industry players have been able to
            identify opportunities, understand how blockchain could be useful
            into today’s industry, and how it could help to define the industry
            of the future.
          </Trans>
        </Typography>
      </StyledBox>
    ),
    image: partnerImage,
    contentImage: partnerContentImage,
  },
];

function DistributorsBlockchainPage() {
  const [showDetails, setShowDetails] = React.useState(undefined);

  return (
    <NewLayout apps="blockchain">
      <Hero
        kind={<Trans>Blockchain</Trans>}
        title={<Trans>The way to lead change</Trans>}
        description={
          <Trans>
            A dedicated software company focused on developing solutions for the
            entire fund industry. By leveraging the power of blockchain
            technology, we can provide you with a secure and reliable platform
            offering improved performance, scalability, and cost savings, while
            also providing enhanced security for your operations.
          </Trans>
        }
        backgroundImage={backgroundImage}
        buttonText={<Trans>Get in touch</Trans>}
        buttonPath="/contact"
      />
      <Container py={8}>
        <Stack spacing={2}>
          <Typography variant="h3">
            <Trans>Why Allfunds Blockchain?</Trans>
          </Typography>
          <Typography variant="h4">
            <Trans>A live blockchain environment</Trans>
          </Typography>
          <Typography variant="small">
            <Trans>
              Allfunds Business Smart Contracts solution: a blockchain
              technology for fund distribution operations that serves as a
              roadmap strategy for the optimal implementation and use of
              blockchain in the value chain.
            </Trans>
          </Typography>
        </Stack>
        <Grid container mt={2} spacing={2}>
          {iconsContent().map(({ title, description, icon, color }) => (
            <Grid item xs={12} sm={6} md={3}>
              <CustomizedIcon
                icon={icon}
                color={color}
                title={title}
                description={description}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Stack
        mt={5}
        sx={{
          background: (theme) => theme.palette.primary.main,
          paddingY: { xs: 4, md: 10 },
          paddingX: { xs: 4, md: 20 },
        }}
        textAlign="center"
        spacing={2}
      >
        <StyledTypography variant="h3">
          <Trans>
            Allfunds Blockchain is at the forefront of innovation, as
            demonstrated by its role in the successful launch of Spain's first
            tokenized fund in 2022. With a dedicated staff of over 20 experts in
            three countries, the team covers Allfunds' global markets and
            provides blockchain-based solutions to all fund value chain
            stakeholders.
          </Trans>
        </StyledTypography>
        {/* <StyledTypography variant="text">
          <Trans>
            It's not about taking years to replicate platforms, but to develop
            blockchain modules and integrate them to understand the benefits of
            Allfunds Blockchain value proposition in the fund industry.
          </Trans>
        </StyledTypography> */}
      </Stack>
      <Container py={{ xs: 5, sm: 10 }}>
        <Typography variant="h3">
          <Trans>Blockchain Value Propositions</Trans>
        </Typography>
        <Grid mt={3} container spacing={2}>
          {valueContent().map(({ title, image }, index) => (
            <Grid item xs={12} sm={6} md={4}>
              <Box
                p={{ xs: 1, sm: 2 }}
                sx={{
                  minHeight: "100%",
                  borderRadius: "5px 5px 0px 0px",
                  background: (theme) => showDetails === index && `${theme.palette.neutral.light}`,
                }}
              >
                <Box
                  sx={{
                    height: "360px",
                    borderRadius: "5px",
                    backgroundImage: `url(${image})`,
                    minHeight: "100%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
                <Box
                  px={2}
                  mt={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h4">{title}</Typography>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowDetails(showDetails === index ? undefined : index)}
                    icon={showDetails === index ? faMinus : faPlus}
                    size="2x"
                    color="#D50032"
                  />
                </Box>
              </Box>
            </Grid>
          ))}
          {valueContent()[showDetails] && (
            <Grid item xs={12}>
              <Box
                mt={-2}
                p={2}
                sx={{
                  borderRadius: `${showDetails === 0 ? "0" : "5px"} ${
                    showDetails === valueContent().length - 1 ? "0" : "5px"
                  } 5px 5px`,
                  background: (theme) => `${theme.palette.neutral.light}`,
                }}
              >
                <Grid container spacing={2} alignitems="center">
                  <Grid item xs={12} sm={6}>
                    {valueContent()[showDetails].content}
                  </Grid>
                  <Grid item xs={12} sm={6} display="flex">
                    <img
                      alt=""
                      src={valueContent()[showDetails].contentImage}
                      style={{ maxWidth: "100%", margin: "auto" }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
      <Box>
        <LatestUpdates title={<Trans>Latest Insights for Blockchain</Trans>} />
      </Box>
    </NewLayout>
  );
}

const StyledTypography = styled(Typography)`
  color: white;
`;

const StyledBox = styled(Box)`
  > p {
    font-size: 16px;
  }
  * li {
    font-size: 16px;
    > b {
      font-weight: 600;
      font-size: 20px;
    }
  }
`;

export default DistributorsBlockchainPage;
